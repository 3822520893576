/*
 * @Author: Matthew Auld
 * @Date: 2018-12-19 13:03:20
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-31 16:09:35
 * @File: TopBar.jsx
 * Copyright 2018 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { IsLoggedIn } from "../User";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientName } from "../config";
import NavbarLogo from "../navbar-logo.svg";
import $ from "jquery";

class TopBar extends Component {
	state = {};

	closeNavbar() {
		$("#topNavBar.navbar-collapse.collapse.show").collapse("hide");
	}

	render() {
		return (
			<nav className="navbar navbar-expand-lg navbar-dark" id="top-bar">
				<Link
					to={IsLoggedIn() ? "/members" : "/"}
					className="navbar-brand"
				>
					<img
						src={NavbarLogo}
						style={{ height: 30 }}
						alt={ClientName}
					/>
				</Link>
				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#topNavBar"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<FontAwesomeIcon icon={["fal", "bars"]} />
				</button>
				<div className="collapse navbar-collapse" id="topNavBar">
					{IsLoggedIn() ? (
						""
					) : (
						<ul className="navbar-nav mr-auto">
							<li className="nav-item active">
								<Link
									to="/"
									onClick={this.closeNavbar}
									className="nav-link"
								>
									Home
								</Link>
							</li>
						</ul>
					)}
					{IsLoggedIn() ? (
						<ul className="navbar-nav ml-auto">
							<li className="nav-item">
								<Link
									to="/members"
									onClick={this.closeNavbar}
									className="nav-link"
								>
									Home
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/logout"
									onClick={this.closeNavbar}
									className="nav-link"
								>
									Log Out
								</Link>
							</li>
						</ul>
					) : (
						<ul className="navbar-nav">
							<li className="nav-item">
								<Link
									to="/login"
									onClick={this.closeNavbar}
									className="nav-link"
								>
									Login
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/register"
									onClick={this.closeNavbar}
									className="nav-link"
								>
									Sign Up
								</Link>
							</li>
						</ul>
					)}
				</div>
			</nav>
		);
	}
}

export default TopBar;
