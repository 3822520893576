/*
 * @Author: Matthew Auld
 * @Date: 2018-12-27 18:48:32
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-27 20:51:18
 * @File: RegistrationPage.jsx
 * Copyright 2018 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";
import { IsLoggedIn } from "../User";

import { ClientName, API_URL } from "../config";
import $ from "jquery";
import Logo from "../navbar-logo.svg";

import "./registration.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class RegistrationPage extends Component {
	state = {
		redirect: false
	};

	constructor() {
		super();

		this.signup = this.signup.bind(this);
	}

	componentWillMount() {
		if (IsLoggedIn()) {
			this.setState({
				redirect: true
			});
		}
	}

	signup(e) {
		e.preventDefault();

		var json_data = JSON.stringify({
			family_name: $("#family_name").val(),
			given_name: $("#given_name").val(),
			email: $("#email-address").val(),
			password: $("#password").val(),
			password_confirmation: $("#password_confirm").val()
		});

		$.ajax({
			url: API_URL + "auth/signup",
			type: "POST",
			dataType: "json",
			data: json_data,
			processData: false,
			contentType: "application/json",
			CrossDomain: true,
			async: true,
			success: (data) => {
				if (data.success) {
					this.setState({ redirect: true });
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log(xhr);
				console.log(ajaxOptions);
				console.log(thrownError);
			}
		});
	}

	render() {
		return this.state.redirect ? (
			<Redirect to="/members" />
		) : (
			<div id="register-page" className="content">
				<div className="container">
					<div id="register-wrapper">
						<div id="register-ux">
							<h1 className="text-center my-2">
								<img
									src={Logo}
									style={{ height: 35, maxWidth: "100%" }}
									alt={ClientName}
								/>
							</h1>
							<p className="lead text-center">Sign Up with</p>
							<div className="row mx-0 alternate-logins">
								<div className="col-3 px-1">
									<Link
										to="#"
										className="bg-fb text-white btn btn-block mb-3"
									>
										<FontAwesomeIcon
											icon={["fab", "facebook-f"]}
										/>
									</Link>
								</div>
								<div className="col-3 px-1">
									<Link
										to="#"
										className="bg-tw text-white btn btn-block mb-3"
									>
										<FontAwesomeIcon
											icon={["fab", "twitter"]}
										/>
									</Link>
								</div>
								<div className="col-3 px-1">
									<Link
										to="#"
										className="bg-li text-white btn btn-block mb-3"
									>
										<FontAwesomeIcon
											icon={["fab", "linkedin"]}
										/>
									</Link>
								</div>
								<div className="col-3 px-1">
									<Link
										to="#"
										className="bg-gp text-white btn btn-block mb-3"
									>
										<FontAwesomeIcon
											icon={["fab", "google-plus-g"]}
										/>
									</Link>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-5 d-flex align-items-center">
									<div className="border-bottom w-100" />
								</div>
								<div className="col-2 text-center">OR</div>
								<div className="col-5 d-flex align-items-center">
									<div className="border-bottom w-100" />
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<form method="post" autoComplete="off">
										<div className="row mb-2">
											<div className="col-12 d-flex align-items-center py-2">
												<label
													className="m-0"
													htmlFor="given_name"
												>
													First Name
												</label>
											</div>
											<div className="col-12">
												<input
													type="text"
													id="given_name"
													name="given_name"
													className="form-control"
												/>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-12 d-flex align-items-center py-2">
												<label
													className="m-0"
													htmlFor="family_name"
												>
													Last Name
												</label>
											</div>
											<div className="col-12">
												<input
													type="text"
													id="family_name"
													name="family_name"
													className="form-control"
												/>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-12 d-flex align-items-center py-2">
												<label
													className="m-0"
													htmlFor="email-address"
												>
													E-mail
												</label>
											</div>
											<div className="col-12">
												<input
													type="email"
													id="email-address"
													name="email"
													className="form-control"
												/>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-12 d-flex align-items-center py-2">
												<label
													className="m-0"
													htmlFor="password"
												>
													Password
												</label>
											</div>
											<div className="col-12">
												<input
													type="password"
													id="password"
													name="password"
													className="form-control"
												/>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-12 d-flex align-items-center py-2">
												<label
													className="m-0"
													htmlFor="password_confirm"
												>
													Confirm Password
												</label>
											</div>
											<div className="col-12">
												<input
													type="password"
													id="password_confirm"
													name="password_confirm"
													className="form-control"
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 py-2">
												<input
													type="submit"
													value="Sign Up"
													className="btn btn-block btn-pp"
													onClick={this.signup}
												/>
											</div>
										</div>
										<div className="row mb-3">
											<div className="col-5 d-flex align-items-center">
												<div className="border-bottom w-100" />
											</div>
											<div className="col-2 text-center">
												OR
											</div>
											<div className="col-5 d-flex align-items-center">
												<div className="border-bottom w-100" />
											</div>
										</div>
										<div className="row">
											<div className="col-12 pb-2">
												<Link
													to="/login"
													className="btn btn-block btn-secondary"
												>
													Sign In
												</Link>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RegistrationPage;
