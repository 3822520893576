/*
 * @Author: Matthew Auld
 * @Date: 2018-12-19 13:03:26
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-31 17:22:28
 * @File: LandingPage.jsx
 * Copyright 2018 JumpButton North - All rights reserved.
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./landing.css";

class LandingPage extends Component {
	state = {};
	render() {
		return (
			<div
				id="landing-page"
				className="content align-items-end justify-content-center"
			>
				<div className="container mb-5">
					<div className="row">
						<div className="col-12 col-md-4">
							<Link to="/login" className="btn btn-pp  btn-block">
								Login
							</Link>
						</div>
						<div className="col-12 col-md-4 text-white text-center py-3">
							OR
						</div>
						<div className="col-12 col-md-4">
							<Link
								to="/register"
								className="btn btn-block btn-outline-secondary"
							>
								Sign Up
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default LandingPage;
