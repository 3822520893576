import React, { Component } from "react";
import { Route } from "react-router-dom";
import "./App.css";

import TopBar from "./components/TopBar";
import Footer from "./components/Footer";

import Logout from "./pages/logout";
import LandingPage from "./pages/landing";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/registration";
import MembersHome from "./pages/members/home";

class App extends Component {
	state = {};

	render() {
		return (
			<div id="application">
				<TopBar />
				<Route exact path="/logout" component={Logout} />
				<Route exact path="/" component={LandingPage} />
				<Route exact path="/login" component={LoginPage} />
				<Route exact path="/register" component={RegisterPage} />
				<Route exact path="/members" component={MembersHome} />
				<Footer
					twitter="twitter"
					facebook="facebook"
					instagram="instagram"
					github="github"
					linkedin="linkedin"
					tumblr="tumblr"
				/>
			</div>
		);
	}
}

export default App;
