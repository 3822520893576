/*
 * @Author: Matthew Auld
 * @Date: 2018-12-19 17:27:51
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-31 17:30:56
 * @File: LoginPage.jsx
 * Copyright 2018 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";
import { IsLoggedIn } from "../User";

import { ClientName, API_URL } from "../config";
import $ from "jquery";
import Logo from "../navbar-logo.svg";

import "./login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LoginPage extends Component {
	state = { redirect: false };

	constructor() {
		super();
		this.login = this.login.bind(this);
	}

	componentWillMount() {
		if (IsLoggedIn()) {
			this.setState({
				redirect: true
			});
		}
	}

	login(e) {
		e.preventDefault();
		$.ajax({
			url: API_URL + "auth/login",
			type: "POST",
			dataType: "json",
			data: JSON.stringify({
				email: $("#email-address").val(),
				password: $("#password").val(),
				remember_me: false
			}),
			processData: false,
			contentType: "application/json",
			CrossDomain: true,
			async: true,
			success: (data) => {
				if (data.success) {
					localStorage.setItem("access_token", data.access_token);
					$.ajax({
						url: API_URL + "auth/user",
						type: "GET",
						dataType: "json",
						data: {},
						processData: false,
						contentType: "application/json",
						CrossDomain: true,
						async: true,
						beforeSend: function(xhr) {
							xhr.setRequestHeader(
								"Authorization",
								"Bearer " + localStorage.getItem("access_token")
							);
						},
						success: (data) => {
							console.log("Success");
							console.log(data);
							if (data.success) {
								localStorage.setItem(
									"user",
									JSON.stringify(data.user)
								);
								this.setState({
									redirect: true
								});
							}
						},
						error: function(xhr, ajaxOptions, thrownError) {
							console.log("Failed");
							console.log(xhr);
							console.log(ajaxOptions);
							console.log(thrownError);
						}
					});
				} else {
					alert(data.message);
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log(xhr);
				console.log(ajaxOptions);
				console.log(thrownError);
			}
		});
	}

	render() {
		return this.state.redirect ? (
			<Redirect to="/members" />
		) : (
			<div id="login-page" className="content">
				<div className="container">
					<div id="login-wrapper">
						<div id="login-ux">
							<h1 className="text-center my-2">
								<img
									src={Logo}
									style={{ height: 35, maxWidth: "100%" }}
									alt={ClientName}
								/>
							</h1>
							<p className="lead text-center">Login with</p>
							<div className="row mx-0 alternate-logins">
								<div className="col-3 px-1">
									<Link
										to="#"
										className="bg-fb text-white btn btn-block mb-3"
									>
										<FontAwesomeIcon
											icon={["fab", "facebook-f"]}
										/>
									</Link>
								</div>
								<div className="col-3 px-1">
									<Link
										to="#"
										className="bg-tw text-white btn btn-block mb-3"
									>
										<FontAwesomeIcon
											icon={["fab", "twitter"]}
										/>
									</Link>
								</div>
								<div className="col-3 px-1">
									<Link
										to="#"
										className="bg-li text-white btn btn-block mb-3"
									>
										<FontAwesomeIcon
											icon={["fab", "linkedin"]}
										/>
									</Link>
								</div>
								<div className="col-3 px-1">
									<Link
										to="#"
										className="bg-gp text-white btn btn-block mb-3"
									>
										<FontAwesomeIcon
											icon={["fab", "google-plus-g"]}
										/>
									</Link>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-5 d-flex align-items-center">
									<div className="border-bottom w-100" />
								</div>
								<div className="col-2 text-center">OR</div>
								<div className="col-5 d-flex align-items-center">
									<div className="border-bottom w-100" />
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<form method="post" autoComplete="off">
										<div className="row mb-2">
											<div className="col-12 d-flex align-items-center py-2">
												<label
													className="m-0"
													htmlFor="email-address"
												>
													E-mail
												</label>
											</div>
											<div className="col-12">
												<input
													type="email"
													id="email-address"
													name="email"
													className="form-control"
												/>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-12 d-flex align-items-center py-2">
												<label
													className="m-0"
													htmlFor="password"
												>
													Password
												</label>
											</div>
											<div className="col-12">
												<input
													type="password"
													id="password"
													name="password"
													className="form-control"
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 py-2">
												<input
													type="submit"
													value="Sign In"
													className="btn btn-block btn-pp"
													onClick={this.login}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-6 py-2 pr-0">
												<label>
													<input
														type="checkbox"
														name="remember"
														id="remember"
														className="mr-2"
													/>
													Remember Me
												</label>
											</div>
											<div className="col-6 text-right py-2 pl-0">
												<Link
													to="/forgot"
													className="text-white"
												>
													Forgot Password?
												</Link>
											</div>
										</div>
										<div className="row">
											<div className="col-12 pb-2">
												<Link
													to="/register"
													className="btn btn-block btn-secondary"
												>
													Sign Up
												</Link>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default LoginPage;
