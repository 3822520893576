/*
 * @Author: Matthew Auld
 * @Date: 2018-12-19 13:15:06
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-31 17:55:05
 * @File: config.js
 * Copyright 2018 JumpButton North - All rights reserved.
 */

module.exports = {
	ClientName: "Player Pager",
	API_URL: "http://api.cms-beta.jumpbuttonnorth.com/api/"
};
