/*
 * @Author: Matthew Auld
 * @Date: 2018-12-27 19:20:43
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-31 17:18:57
 * @File: home.jsx
 * Copyright 2018 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";

import { Redirect } from "react-router-dom";

class MembersHome extends Component {
	state = { redirect: false };

	componentWillMount() {
		if (!localStorage.getItem("access_token")) {
			this.setState({
				redirect: true
			});
		}
	}

	render() {
		return this.state.redirect ? (
			<Redirect to="/login" />
		) : (
			<div id="members-home" className="content" />
		);
	}
}

export default MembersHome;
