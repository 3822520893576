/*
 * @Author: Matthew Auld
 * @Date: 2018-12-19 13:05:20
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-27 19:06:32
 * @File: Footer.jsx
 * Copyright 2018 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Footer extends Component {
	state = {};
	render() {
		return (
			<div id="footer" className="py-2 container-fluid">
				<div className="row">
					<div className="col-12 col-md-4 d-flex align-items-center text-white footer-links">
						<Link to="/about">About</Link>
						<Link to="/terms">Terms</Link>
						<Link to="/privacy">Privacy</Link>
						<Link to="/help">Help</Link>
					</div>
					<div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
						Copyright &copy; 2019 - Arctic Media Solutions Inc.
					</div>
					<div className="col-12 col-md-4 media-links">
						<div>
							{this.props.twitter && (
								<Link
									to={this.props.twitter}
									className="twitter"
								>
									<FontAwesomeIcon
										icon={["fab", "twitter"]}
									/>
								</Link>
							)}
							{this.props.facebook && (
								<Link
									to={this.props.facebook}
									className="facebook"
								>
									<FontAwesomeIcon
										icon={["fab", "facebook-f"]}
									/>
								</Link>
							)}
							{this.props.instagram && (
								<Link
									to={this.props.instagram}
									className="instagram"
								>
									<FontAwesomeIcon
										icon={["fab", "instagram"]}
									/>
								</Link>
							)}
							{this.props.github && (
								<Link to={this.props.github} className="github">
									<FontAwesomeIcon
										icon={["fab", "github-alt"]}
									/>
								</Link>
							)}
							{this.props.linkedin && (
								<Link
									to={this.props.linkedin}
									className="linkedin"
								>
									<FontAwesomeIcon
										icon={["fab", "linkedin"]}
									/>
								</Link>
							)}
							{this.props.tumblr && (
								<Link to={this.props.tumblr} className="tumblr">
									<FontAwesomeIcon icon={["fab", "tumblr"]} />
								</Link>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Footer;
