/*
 * @Author: Matthew Auld
 * @Date: 2018-12-27 20:06:15
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-31 16:14:04
 * @File: logout.jsx
 * Copyright 2018 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { API_URL } from "../config";
import $ from "jquery";
import { getAccessToken as UserToken } from "../User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LogoutPage extends Component {
	state = {
		redirect: !UserToken()
	};

	componentDidMount() {
		$.ajax({
			url: API_URL + "auth/logout",
			type: "POST",
			dataType: "json",
			data: {},
			processData: false,
			contentType: "application/json",
			CrossDomain: true,
			async: true,
			beforeSend: function(xhr) {
				xhr.setRequestHeader("Authorization", "Bearer " + UserToken());
			},
			success: (data) => {
				if (data.success) {
					setTimeout(() => {
						localStorage.clear();
						this.setState({ redirect: true });
					}, 500);
				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				console.log(xhr);
				console.log(ajaxOptions);
				console.log(thrownError);
			}
		});
	}

	render() {
		//return <Redirect to="/" />;
		return this.state.redirect ? (
			<Redirect to="/" />
		) : (
			<div className="content align-items-center justify-content-center">
				<h1 className="text-center">
					Please Wait
					<br />
					Logging Out
					<br />
					<FontAwesomeIcon
						icon={["fal", "spinner-third"]}
						className="fa-spin"
					/>
				</h1>
			</div>
		);
	}
}

export default LogoutPage;
