import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "jquery/dist/jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { faBars, faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import {
	faTwitter,
	faFacebookF,
	faInstagram,
	faGithubAlt,
	faLinkedin,
	faTwitterSquare,
	faTumblr,
	faGooglePlusG
} from "@fortawesome/free-brands-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
	faBars,
	faTwitter,
	faFacebookF,
	faInstagram,
	faGithubAlt,
	faLinkedin,
	faTwitterSquare,
	faTumblr,
	faGooglePlusG,
	faSpinnerThird
);

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
