/*
 * @Author: Matthew Auld
 * @Date: 2018-12-27 19:37:11
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-31 17:25:34
 * @File: User.js
 * Copyright 2018 JumpButton North - All rights reserved.
 */

import { API_URL } from "./config";
import $ from "jquery";

export function IsLoggedIn() {
	return (
		localStorage.getItem("access_token") !== undefined &&
		localStorage.getItem("access_token") !== null
	);
}

export function getAccessToken() {
	return localStorage.getItem("access_token");
}

export function getUserDetails(callback) {
	return JSON.parse(localStorage.getItem("user"));
}
